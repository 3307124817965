import fetch from 'common/fetch';

// 批量下单
export function getBatchOrderList(params) {
  return fetch({
    url: '/system/task/list',
    method: 'post',
    data: params
  });
}

// 下载数据 /system/ware/file
export function downloadTemplate(params) {
  return fetch({
    url: '/batch/order/oss/file/downloadTarget?fileName=' + params,
    method: 'get',
    responseType: 'blob'
  });
}

// 批量下单上传文件
export function Add(params) {
  return fetch({
    url: '/system/task/add',
    method: 'post',
    params
  });
}
// 下载模板
export function download(fileName, bucket) {
  return fetch({
    url:
      '/system/task/exportTemplate',
    method: 'get',
    responseType: 'blob'
  });
}
