<template>
  <div class="upAdd">
    <div class="titleH2">
      <div class="title">Batch Order Add</div>
      <el-upload
        style="margin-left: 16px"
        class="upload-demo"
        :action="actionUrl"
        :data="datas"
        :on-preview="handlePreview"
        :on-remove="handleRemove"
        :on-success="handleSuccess"
        :before-remove="beforeRemove"
        :show-file-list="false"
        multiple
      >
        <el-button size="small" type="primary" class="sl-icon-upload">
          Click on the upload
        </el-button>
      </el-upload>
      <div class="display">
        <el-button class="button sl-icon-download" @click="downloadTemplate">
          Download VSP template
        </el-button>
      </div>

      <div class="panel-body">
        <el-table
          :data="tableData"
          style="width: 100%"
          :header-cell-style="{ background: '#e8f1fe' }"
        >
          <!-- 修改无数据时 提示文字 -->
          <template slot="empty" class="logo_no">
            <!-- <img :src="emptyImgSrc" alt="" width="118px" height="110px"> -->
            <p style="color: #969799">No more data</p>
          </template>
          <el-table-column label="Order Type" width="180">
            VSP
          </el-table-column>
          <el-table-column prop="id" label="Task Id" width="180" />
          <el-table-column prop="created" label="Create Time" />
          <el-table-column prop="creator" label="Creator" />
          <el-table-column prop="taskState" label="Task Status">
            <template slot-scope="scope">
              <span v-if="scope.row.taskState === 0"> Ordering </span>

              <span v-if="scope.row.taskState === 4">
                Partial Order Successfully
              </span>

              <span v-if="scope.row.taskState === 2"> Order Failed </span>

              <span v-if="scope.row.taskState === 3"> Order Successfully </span>
            </template>
          </el-table-column>
          <el-table-column prop="successCount" label="Succeed Number" />
          <el-table-column prop="failCount" label="Failed Number" />
          <el-table-column
            prop="operate"
            label="Export"
            width="160"
            fixed="right"
          >
            <template slot-scope="scope">
              <el-button type="primary" @click="downFile(scope.row)">
                Export
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <div class="paginationDiv">
          <el-pagination
            style="float: right; margin: 17px 17px 0 0"
            :current-page="pages.currentPage"
            :page-size="pages.size"
            layout="total, sizes, prev, pager, next, jumper"
            :total="pages.total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  download,
  getBatchOrderList,
  downloadTemplate
} from '@/api/batchOrder/api';

export default {
  name: '',
  components: {},
  data() {
    return {
      emptyImgSrc: '',
      datas: { creator: navigator.userAgent },
      // 上传地址
      actionUrl: process.env.VUE_APP_BASE_API + '/system/task/add',
      tableData: [],
      // 分页数据
      pages: {
        total: 0,
        size: 10,
        currentPage: 1
      },
      excel: 'batchOrderTemplate.xlsx'
    };
  },
  computed: {},
  watch: {},
  created() {
    this.emptyImgSrc = '';
  },
  mounted() {
    this.getList();
  },
  methods: {
    getList() {
      getBatchOrderList({
        pageSize: this.pages.size,
        pageNo: this.pages.currentPage
      }).then(res => {
        if (res.success && res.code === 200) {
          this.tableData = res.data.dataList;
          this.pages.total  = res.data.totalCount;
        } else {
          this.tableData = [];
          this.pages.total  = 0;
          this.$message({
            message: res.msg,
            type: 'error'
          });
        }
      });
    },
    handleCurrentChange(val) {
      this.pages.currentPage = val;
      this.getList();
    },
    handleSizeChange(val) {
      this.pages.size = val;
      this.getList();
    },
    handleRemove(file, fileList) {
      //console.log(file, fileList);
    },
    handlePreview(file) {
      //console.log(file);
    },

    beforeRemove(file, fileList) {
      return this.$confirm(`Sure to remove ${file.name}？`);
    },
    handleSuccess(res) {
      console.log(res);
      if (res.code == 200) {
        this.$message({
          type: 'success',
          message: 'Operation succeeded'
        });
        this.getList();
      }
    },
    downloadTemplate() {
      let fileName = this.excel;
      let bucket = 'xx-vsp-trade';
      download(fileName, bucket).then(res => {
        var downloadUrl = window.URL.createObjectURL(res);
        var anchor = document.createElement('a');
        anchor.href = downloadUrl;
        anchor.download = 'VSPtemplate.xlsx';
        anchor.click();
        window.URL.revokeObjectURL(res);
      });
    },
    downFile(row) {
      let resultFileUrl = row.resultFileUrl;
      if (row.resultFileUrl) {
        downloadTemplate(resultFileUrl).then(res => {
          var downloadUrl = window.URL.createObjectURL(res);
          var anchor = document.createElement('a');
          anchor.href = downloadUrl;
          anchor.download = row.resultFileUrl;
          anchor.click();
          window.URL.revokeObjectURL(res);
        });
      } else {
        this.$message({
          message: 'The source file is empty',
          type: 'error'
        });
      }
    }
  }
};
</script>
<style lang="less" scoped>
//@import url(); 引入公共css类
.upAdd {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background: white;

  .upload-demo {
    display: inline-block;
  }

  .display {
    margin-left: 10px;
    display: inline-block;
  }
  .title {
    height: 66px;
    border-bottom: 1px solid rgba(183, 188, 201, 0.3);
    padding: 24px 16px;
    font-size: 18px;
    font-weight: Bold;
    line-height: 17px;
    margin-bottom: 20px;
  }
  .button {
    padding: 0 12px;
  }
  .paginationDiv {
    height: 60px;
    .pagination {
      margin-top: 20px;
      float: right;
    }
  }
}
.panel-body {
  padding: 20px 16px 0;
}
/deep/.el-button {
  // padding: 0 12px !important;
  //width: 100px;
  font-family: ArialMT;
  font-size: 14px;
}

::v-deep .el-button--primary {
  color: #fff;
  background-color: #2262ff;
  border-color: #2262ff;
}
::v-deep .el-button--text {
  color: #2262ff;
}

::v-deep .el-radio-group {
  .el-radio__label {
    color: #242526;
  }
  .is-checked {
    .el-radio__inner {
      background-color: #2262ff;
    }
    .el-radio__label {
      color: #242526;
    }
  }
}
::v-deep .el-table {
  .cell {
    white-space: pre-wrap;
    word-break: keep-all;
  }
}

::v-deep .el-pager {
  li {
    border: 0;
  }
}
</style>
